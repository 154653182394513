.banner-with-text .banner-with-text__container {
  position: relative;
  display: flex;
  justify-content: var(--content-position-desktop);
  min-height: 47.6vw;
  padding: 318px 100px;
  background-color: #2b2b2b;
  overflow: hidden;
}
.banner-with-text .banner-with-text__container.banner-with-text__container--overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 67.29%, rgba(0, 0, 0, 0.35) 100%);
}
.banner-with-text .banner-with-text__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--image-position-desktop);
}
.banner-with-text .banner-with-text__content {
  position: relative;
  z-index: 1;
  max-width: 845px;
  text-align: var(--text-position-desktop);
}
.banner-with-text .banner-with-text__heading,
.banner-with-text .banner-with-text__text {
  color: var(--text-color);
}
.banner-with-text .banner-with-text__label,
.banner-with-text .banner-with-text__heading {
  font-family: "GT Super Ds", sans-serif;
  font-weight: 500;
}
.banner-with-text .banner-with-text__label {
  margin-bottom: 3px;
  font-size: 20px;
}
.banner-with-text .banner-with-text__heading {
  font-size: 50px;
  line-height: 1.1;
}
.banner-with-text .banner-with-text__text {
  margin-top: 3px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.01em;
}
.banner-with-text .banner-with-text__button {
  margin-top: 19px;
  text-decoration: unset;
}
.banner-with-text .banner-with-text__toggle-video-button {
  background-color: red;
  position: absolute;
  top: auto;
  bottom: 50px;
  left: 50%;
  z-index: 1;
  display: block;
  padding: 0;
  background-color: transparent;
  line-height: 0;
  transform: translateX(-50%);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.banner-with-text .banner-with-text__toggle-video-button:focus-visible {
  outline: 2px solid #ffffff;
  outline-offset: 3px;
}
.banner-with-text .banner-with-text__toggle-video-button.hidden {
  display: none;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .banner-with-text .banner-with-text__toggle-video-button:hover {
    opacity: 0.7;
  }
}
.banner-with-text .banner-with-text__toggle-video-button-icon {
  display: block;
}
.banner-with-text .banner-with-text__toggle-video-button-icon svg {
  width: 28px;
  height: 30px;
}
.banner-with-text .banner-with-text__toggle-video-button-icon.hidden {
  display: none;
}
@media (max-width: 1500px) {
  .banner-with-text .banner-with-text__container {
    padding: 215px 100px;
  }
  .banner-with-text .banner-with-text__content {
    max-width: 630px;
  }
}
@media (max-width: 991px) {
  .banner-with-text .banner-with-text__container {
    padding: 177px 75px;
  }
  .banner-with-text .hero-banner__description {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .banner-with-text .banner-with-text__container {
    justify-content: var(--content-position-mobile);
    padding-left: 35px;
    padding-right: 35px;
  }
  .banner-with-text .banner-with-text__image {
    object-position: var(--image-position-mobile);
  }
  .banner-with-text .banner-with-text__content {
    max-width: 550px;
    text-align: var(--text-position-mobile);
  }
  .banner-with-text .banner-with-text__label {
    margin-bottom: 7px;
  }
  .banner-with-text .banner-with-text__heading {
    font-size: 40px;
    line-height: 1.2;
  }
  .banner-with-text .banner-with-text__text {
    margin-top: 7px;
    font-size: 14px;
    line-height: 1.29;
  }
  .banner-with-text .banner-with-text__button {
    margin-top: 21px;
  }
}
@media (max-width: 575px) {
  .banner-with-text .banner-with-text__content {
    max-width: 550px;
  }
  .banner-with-text .banner-with-text__label {
    margin-bottom: 14px;
  }
  .banner-with-text .banner-with-text__heading {
    font-size: 25px;
    line-height: 1.1;
  }
  .banner-with-text .banner-with-text__text {
    margin-top: 14px;
    font-size: 13px;
    line-height: 1.1;
  }
  .banner-with-text .banner-with-text__button {
    margin-top: 23px;
  }
}
@media (max-width: 480px) {
  .banner-with-text .banner-with-text__container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner-with-text .banner-with-text__content {
    max-width: 350px;
  }
}